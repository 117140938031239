import React from 'react';
import { Route } from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list.jsx';
import menus from './menu.jsx';
import MemberMenu from './member-menu.jsx';
import { connect } from "react-redux";
import { AccessRight, Status, LoginType } from "../../util/Constant";
import { stringIsNullOrEmpty } from '../../util/Util.js';

class SidebarNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: -1,
            clicked: -1,
            menusToShow: JSON.parse(JSON.stringify(menus)),
            fullMenu: []
        };
    }

    filterMenuByPermission = () => {
        var usedMenu = this.props.authState.userData.loginType == LoginType._LOGIN_TYPE_MEMBER ? MemberMenu : menus;
        
        this.state.menusToShow = JSON.parse(JSON.stringify(usedMenu));
        if (this.props.authState.userData.accessString !== undefined) {
          var userPermissionData = this.props.authState.userData.accessString.split(";");
            var permissionArrKey = [];

            for (var i = 0; i < userPermissionData.length; i++) {
                var keyValuePermission = userPermissionData[i].split(":");
                if (keyValuePermission.length == 2) {
                    if (keyValuePermission[1][AccessRight._READ_BIT_POSITION] == Status._ENABLED.toString()) {
                        permissionArrKey.push(keyValuePermission[0] + AccessRight._READ_PERMISSION_STRING);
                    }
                    if (keyValuePermission[1][AccessRight._WRITE_BIT_POSITION] == Status._ENABLED.toString()) {
                        permissionArrKey.push(keyValuePermission[0] + AccessRight._WRITE_PERMISSION_STRING);
                    }
                }
            }
            //first filter away non use children
            for (var i = 0; i < this.state.menusToShow.length; i++) {
                if (this.state.menusToShow[i].children) {
                    this.state.menusToShow[i].children = this.state.menusToShow[i].children.filter(item => {
                        //let permission = item['permission'];
                        //let temp = permission.substring(0, permission.indexOf(AccessRight._READ_PERMISSION_STRING));
                        //let temp1 = temp.split(' ');
                        //let match = '';
                        //temp1.map((word) => {
                        //  match += word.charAt(0);
                        //});
                        //if (match !== undefined) {
                        //    match += AccessRight._READ_PERMISSION_STRING;
                        //    return permissionArrKey.filter(item => item == match).length != 0;
                        //}
                      //console.log(item['numberLabel'].trim())
                      //console.log(item["permission"])
                        if (item["permission"]) {
                            let permission = item['numberLabel'].trim();
                            if (permission.substring(0, permission.indexOf(".")) === "13") {
                               permission = "13.";
                            }
                            //else if (permission.substring(0, permission.indexOf(".")) === "14") {
                            //   permission = "14.";
                            //}
                            if (permission !== undefined && permission !== "") {
                               permission += AccessRight._READ_PERMISSION_STRING;
                               return permissionArrKey.filter(item => item == permission).length != 0;
                            }
                            else {
                               return true;
                            } 
                        } 
                        else {
                            return true;
                        }
                    });
                }
            }

            //secondly filter away parent
            this.state.menusToShow = this.state.menusToShow.filter(item => {
                if (!item.children || item.children.length == 0) {
                    if (item.isDummy) {
                        return false;
                    }
                    else {
                        //let permission = item.permission;
                        //let match = '';
                        //if (permission !== undefined){
                        //    let temp = permission.substring(0, permission.indexOf(AccessRight._READ_PERMISSION_STRING));
                        //    let temp1 = temp.split(' ');
                        //    temp1.map((word) => {
                        //      match += word.charAt(0);
                        //    });
                        //}
                        //if (!stringIsNullOrEmpty(match)) {
                        //    match += AccessRight._READ_PERMISSION_STRING;
                        //    return permissionArrKey.filter(item => item == match).length != 0;
                        //}
                        let permission = item.numberLabel.trim();
                        if (permission !== undefined && permission !== "") {
                            permission += AccessRight._READ_PERMISSION_STRING;
                            return permissionArrKey.filter(item => item == permission).length != 0;
                        }
                        else {
                            return true;
                        }
                    }
                }
                else {
                    return true;
                }
            });
            this.state.fullMenu = this.state.menusToShow;
        }
    }

    handleExpand(e, i, match) {
        e.preventDefault();

        if (this.state.clicked === -1 && match) {
            this.setState(state => ({
                active: -1,
                clicked: 1
            }));
        } else {
            this.setState(state => ({
                active: (this.state.active === i ? -1 : i),
                clicked: 1
            }));
        }
    }

    render() {
        this.filterMenuByPermission();

        return (
            <ul className="nav">
                {this.state.menusToShow.map((menu, i) => {
                    return <Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => {
                        return <SidebarNavList
                            data={menu}
                            key={i}
                            expand={(e) => this.handleExpand(e, i, match)}
                            active={i === this.state.active}
                            clicked={this.state.clicked}
                            fullMenu={this.state.fullMenu}
                        />
                    }} />
                })}
            </ul>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    null
)(SidebarNav);