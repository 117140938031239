import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "./../../components/panel/panel.jsx";
import {
    ApiUrl,
    ApiKey,
    TransactionType,
    TransactionStatus,
    LanguageKey,
    Role,
    AccessRight,
    WebUrl
} from "../../util/Constant";
import { numberWithCommas, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import { showMessage } from "../../redux/AppAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { mdiAccount, mdiFileCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";

const DashboardV1 = (props) => {
    var _dispatch = useDispatch();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [pendingTransaction, setPendingTransaction] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalDepositData, setTotalDepositData] = useState([]);
    const [totalWithdrawal, setTotalWithdrawal] = useState(0);
    const [totalWithdrawalData, setTotalWithdrawalData] = useState([]);
    const [instantPaySetting, setInstantPaySetting] = useState([]);
    const [rejectReason, setRejectReason] = useState([]);
    const [topWithdrawal, setTopWithdrawal] = useState([]);
    const [topDeposit, setTopDeposit] = useState([]);
    const [startDate, setStartDate] = useState(moment().toDate());
    const [totalMembers, setTotalMembers] = useState(0);
    const [totalNewMembers, setTotalNewMembers] = useState(0);
    const [totalPerformDeposit, settotalPerformDeposit] = useState(0);
    const [topWinners, setTopWinners] = useState([]);
    const [topLosers, setTopLosers] = useState([]);
    const [topBettersCount, setTopBettersCount] = useState(30);
    let role1IsAllowed = checkIfPermissionExist(
          AccessRight._DASHBOARD_DETAILS_PERMISSION,
          true
        );

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
        setInterval(init, 60000);
        // getTopBetters(topBettersCount);
        getTopWinnersLosers(topBettersCount);
    }, []);

    const getTopBetters = async (value) => {
        {
            setTopBettersCount(value);
            await fetch(ApiUrl._API_GET_TOP_WINNERS_AND_LOSERS + "?count=" + value, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        let data = responseJson[ApiKey._API_DATA_KEY];
                        let tempWinners = [];
                        let tempLosers = [];
                        data["winners"].map((record, index) => {
                            if (record['winning'] >= 0) {
                                tempWinners.push(record);
                            }
                        })
                        data["losers"].map((record, index) => {
                            if (record['winning'] < 0) {
                                tempLosers.push(record);
                            }
                        })
                        setTopWinners(tempWinners);
                        setTopLosers(tempLosers);
                    }
                });
        }
    }
    /// <summary>
    /// Author : -
    /// </summary>

    
    const init = async () => {
        await fetch(ApiUrl._API_GET_TOTAL_TRANSACTION_AMOUNT_COUNT_BY_STATUS_TYPE, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY])) {
                        let pendingTransactions = 0;
                        responseJson[ApiKey._API_DATA_KEY].map((data, index) => {
                            if (data.approvalStatus == TransactionStatus._OPEN) {
                                pendingTransactions += data.totalCount;
                            } else if (data.approvalStatus == TransactionStatus._APPROVED) {
                                if (data.transactionTypeId == TransactionType._DEPOSIT) {
                                    setTotalDepositData(data);
                                    setTotalDeposit(data.totalTransactionAmount);
                                } else if (
                                    data.transactionTypeId == TransactionType._WITHDRAWAL
                                ) {
                                    setTotalWithdrawalData(data);
                                    setTotalWithdrawal(data.totalTransactionAmount);
                                }
                            }
                        });
                        setPendingTransaction(pendingTransactions);
                    }
                }
            });

        await fetch(
            ApiUrl._API_GET_TOP_MEMBER_OF_TRANSACTION_BY_STATUS +
            "?status=" +
            TransactionStatus._APPROVED,
            {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_APPLICATION_JSON,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    responseJson[ApiKey._API_DATA_KEY].map((data, index) => {
                        if (data["transactionTypeId"] == TransactionType._DEPOSIT) {
                            setTopDeposit(data["values"]);
                        } else if (
                            data["transactionTypeId"] == TransactionType._WITHDRAWAL
                        ) {
                            setTopWithdrawal(data["values"]);
                        }
                    });
                }
            });

        await fetch(ApiUrl._API_GET_USER_INSTANT_PAY_SETTING + "?all=true", {
            method: ApiKey._API_GET_USER_INSTANT_PAY_SETTING,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setInstantPaySetting(responseJson[ApiKey._API_DATA_KEY]);
                }
            });

        await fetch(ApiUrl._API_GET_USER_REJECT_CODE + "?all=true", {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setRejectReason(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
        {
            await fetch(ApiUrl._API_GET_TOTAL_REGISTERED_MEMBER_UNDER_MAIN_ACCOUNT + "?all=true", {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setTotalMembers(responseJson[ApiKey._API_DATA_KEY]);
                    }
                });

            await fetch(ApiUrl._API_GET_TOTAL_REGISTERED_MEMBER_UNDER_MAIN_ACCOUNT, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setTotalNewMembers(responseJson[ApiKey._API_DATA_KEY]);
                    }
                });

            await fetch(ApiUrl._API_GET_ALL_TOTAL_DEPOSIT_TRANSACTION_BY_TODAY_NEW, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        settotalPerformDeposit(responseJson[ApiKey._API_DATA_KEY]);
                    }
                });
        }
    };

    const getTopWinnersLosers = async (value) => {
        await fetch(ApiUrl._API_GET_TOP_WINNERS_LOSERS + "?count=" + value, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {

                    let data = responseJson[ApiKey._API_DATA_KEY];

                    let tempWinners = [];
                    let tempLosers = [];
                    data.map((record, index) => {
                        if (record['winning'] > 0) {
                            tempWinners.push(record);
                        }else{
                            tempLosers.push(record);
                            tempLosers.sort((a, b) => a.winning - b.winning);

                        }
                    })
                    // data.map((record, index) => {
                    //     if (record['winning'] < 0) {
                    //         tempLosers.push(record);
                    //     }
                    // })
                    setTopWinners(tempWinners);
                    setTopLosers(tempLosers);
                }
            });
        }
    /// <summary>
    /// Author : -
    /// </summary>
    async function performRewardCalculation() {
        var responseJson = await ApiEngine.post(
            ApiUrl._API_PERFORM_GAME_CALCULATION +
            "?date=" +
            moment(startDate).format("YYYY-MM-DD")
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
    }

    return (
        <div>
            <ol className="breadcrumb float-xl-right">
                <li className="breadcrumb-item">
                    <Link to="/dashboard/v1">{t("HOME")}</Link>
                </li>
                <li className="breadcrumb-item active">{t("DASHBOARD")}</li>
            </ol>
            <h1 className="page-header">{t("DASHBOARD")}</h1>

            <div className="row" style={{ justifyContent: "space-between", margin: "0 20px" }}>
                <div className={"col-xl-" + (role1IsAllowed ? "2" : "12") + " col-md-" + (role1IsAllowed ? "4" : "12")}>
                    <div className="widget widget-stats bg-blue">
                        <div className="stats-icon">
                            <i className="fa fa-hand-holding-usd"></i>
                        </div>
                        <div className="stats-info">
                            <h4>{t("PENDING_TRANSACTIONS")}</h4>
                            <p>{numberWithCommas(pendingTransaction)}</p>
                        </div>
                        <div className="stats-link">
                            <Link to="/transaction/transactionenquiry">
                                {t("VIEW_DETAIL")}{" "}
                                <i className="fa fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                {role1IsAllowed ? <div className="col-xl-2 col-md-4">
                    <div className="widget widget-stats bg-green">
                        <div className="stats-icon">
                            <i className="fa fa-money-bill"></i>
                        </div>
                        <div className="stats-info">
                            <div className="header-wrapper" style={{ display: "flex" }}>
                                <h4>{t("TOTAL_DEPOSIT")}</h4>
                                <div className="right-side-wrapper">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className="total-deposit-player">
                                            <Icon path={mdiAccount}
                                                size={1}
                                                color={"white"}
                                            />
                                            <span className="tooltip-text left">{t("TOTAL_DEPOSIT_PLAYERS")}</span>
                                        </div>
                                        <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalDepositData.totalDepositPlayers ? totalDepositData.totalDepositPlayers : "0"}</div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className="total-deposit-approved">
                                            <Icon path={mdiFileCheck}
                                                size={1}
                                                color={"white"}
                                            />
                                            <span className="tooltip-text right">{t("TOTAL_DEPOSIT_APPROVED")}</span>
                                        </div>
                                        <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalDepositData.totalCount ? totalDepositData.totalCount : "0"}</div>
                                    </div>
                                </div>
                            </div>
                            <p>{numberWithCommas(totalDeposit)}</p>
                        </div>
                        <div className="stats-link">
                            <Link to="/transaction/deposit">
                                {t("VIEW_DETAIL")}{" "}
                                <i className="fa fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
                </div> : <></>}
                {role1IsAllowed ? <div className="col-xl-2 col-md-4">
                    <div className="widget widget-stats bg-red">
                        <div className="stats-icon">
                            <i className="fa fa-chart-bar"></i>
                        </div>
                        <div className="stats-info">
                            <div className="header-wrapper" style={{ display: "flex" }}>
                                <h4>{t("TOTAL_WITHDRAWAL")}</h4>
                                <div className="right-side-wrapper">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className="total-withdrawal-player">
                                            <Icon path={mdiAccount}
                                                size={1}
                                                color={"white"}
                                            />
                                            <span className="tooltip-text left">{t("TOTAL_WITHDRAWAL_PLAYERS")}</span>
                                        </div>
                                        <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalWithdrawalData.totalWithdrawalPlayers ? totalWithdrawalData.totalWithdrawalPlayers : "0"}</div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className="total-withdrawal-approved">
                                            <Icon path={mdiFileCheck}
                                                size={1}
                                                color={"white"}
                                            />
                                            <span className="tooltip-text right">{t("TOTAL_WITHDRAWAL_APPROVED")}</span>
                                        </div>
                                        <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalWithdrawalData.totalCount ? totalWithdrawalData.totalCount : "0"}</div>
                                    </div>
                                </div>
                            </div>
                            <p>{totalWithdrawal > 0 ? "-" : ""}{numberWithCommas(totalWithdrawal)}</p>
                        </div>
                        <div className="stats-link">
                            <Link to="/transaction/withdrawal">
                                {t("VIEW_DETAIL")}{" "}
                                <i className="fa fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
          </div> : <></>}
                {role1IsAllowed ? <div className="col-xl-2 col-md-6">
                    <div className="widget widget-stats bg-custom-yellow">
                        <div className="stats-icon">
                            <i className="fa fa-solid fa-users"></i>
                        </div>
                        <div className="stats-info">
                            <h4>{t("TOTAL_MEMBERS_REGISTERED")}</h4>
                            <p>{totalMembers}</p>
                        </div>
                        <div className="stats-link">
                            <Link to="/account/manageMember">
                                {t("VIEW_DETAIL")}{" "}
                                <i className="fa fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
          </div> : <></>}
                {role1IsAllowed ? <div className="col-xl-2 col-md-6">
                    <div className="widget widget-stats bg-custom-purple">
                        <div className="stats-icon">
                            <i className="fa fa-solid fa-users"></i>
                        </div>
                        <div className="stats-info">
                            <h4>{t("NEW_MEMBERS_IN_7_DAYS / DEPOSITED_MEMBER")}</h4>
                            <p>{totalNewMembers} / {totalPerformDeposit}</p>
                        </div>
                        <div className="stats-link">
                            <Link to="/account/TodayNewMembers">
                                {t("VIEW_DETAIL")}{" "}
                                <i className="fa fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
                </div> : <></>}             
            </div>
            {role1IsAllowed ? <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-3">
                            <Panel>
                                <PanelHeader>{t("TOP_DEPOSIT")}</PanelHeader>
                                <PanelBody className="p-0">
                                    <table className="table table-valign-middle mb-0 dashboard-table">
                                        <thead>
                                            {topDeposit.length > 0 ? (
                                                topDeposit.map((deposit, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{deposit.memberName + " (" + deposit.memberUsername + ") "}</td>
                                                            <td>
                                                                {numberWithCommas(deposit.totalTransaction)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="100%" style={{ textAlign: "center" }}>
                                                        {t("NO_RECORD")}
                                                    </td>
                                                </tr>
                                            )}
                                        </thead>
                                    </table>
                                </PanelBody>
                            </Panel>
                        </div>
                        <div className="col-xl-3">
                            <Panel>
                                <PanelHeader>{t("TOP_WITHDRAWAL")}</PanelHeader>
                                <PanelBody className="p-0">
                                    <table className="table table-valign-middle mb-0 dashboard-table">
                                        <thead>
                                            {topWithdrawal.length > 0 ? (
                                                topWithdrawal.map((withdrawal, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{withdrawal.memberName + " (" + withdrawal.memberUsername + ") "}</td>
                                                            <td>
                                                                {numberWithCommas(withdrawal.totalTransaction)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="100%" style={{ textAlign: "center" }}>
                                                        {t("NO_RECORD")}
                                                    </td>
                                                </tr>
                                            )}
                                        </thead>
                                    </table>
                                </PanelBody>
                            </Panel>
                        </div>
                        <div className="col-xl-3">
                            <Panel>
                                <PanelHeader>{t("TOP")}<input className="desktop-top-bettor" type="number" step="1" min={0} max={100} defaultValue={topBettersCount} onChange={(e) => getTopWinnersLosers(topBettersCount)} />{t("WINNER")}</PanelHeader>
                                <PanelBody className="p-0">
                                    <table className="table table-valign-middle mb-0 dashboard-table">
                                        <thead>
                                            {topWinners.length > 0 ? (
                                                topWinners.map((winners, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{winners.username}</td>
                                                            <td className="text-align-right">
                                                                {numberWithCurrencyFormat(winners.winning, 2, true)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="100%" style={{ textAlign: "center" }}>
                                                        {t("NO_RECORD")}
                                                    </td>
                                                </tr>
                                            )}
                                        </thead>
                                    </table>
                                </PanelBody>
                            </Panel>
                        </div>
                        <div className="col-xl-3">
                            <Panel>
                                <PanelHeader>{t("TOP_LOSER", { value: topBettersCount })}</PanelHeader>
                                <PanelBody className="p-0">
                                    <table className="table table-valign-middle mb-0 dashboard-table">
                                        <thead>
                                            {topLosers.length > 0 ? (
                                                topLosers.map((losers, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{losers.username}</td>
                                                            <td className="text-align-right">
                                                                {numberWithCurrencyFormat(losers.winning, 2, true)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="100%" style={{ textAlign: "center" }}>
                                                        {t("NO_RECORD")}
                                                    </td>
                                                </tr>
                                            )}
                                        </thead>
                                    </table>
                                </PanelBody>
                            </Panel>
                        </div>
                        <div className="col-xl-4">
                            <Panel>
                                <PanelHeader>{t("INSTANT_PAY_SETTINGS")}</PanelHeader>
                                <PanelBody className="p-0">
                                    <div className="table-responsive">
                                        <table className="table table-valign-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{t("NAME")}</th>
                                                    <th>{t("STATUS")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {instantPaySetting.length > 0 ? (
                                                    instantPaySetting.map((setting, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {!stringIsNullOrEmpty(setting.image) ? (
                                                                        <img
                                                                            className="bank-icon"
                                                                            style={{ objectFit: "contain" }}
                                                                            src={setting.image}
                                                                        />
                                                                    ) : (
                                                                        <span> - </span>
                                                                    )}
                                                                </td>
                                                                <td>{setting.instantPayGatewayString}</td>
                                                                <td>
                                                                    {setting.status ? (
                                                                        <span className="badge badge-secondary badge-green">
                                                                            {t("ACTIVE")}
                                                                        </span>
                                                                    ) : (
                                                                            <span className="badge badge-secondary badge-danger">
                                                                            {t("INACTIVE")}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="100%" style={{ textAlign: "center" }}>
                                                            {t("NO_RECORD")}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                        <div className="col-xl-2">
                            <Panel>
                                <PanelHeader>{t("REJECT_REASONS")}</PanelHeader>
                                <PanelBody className="p-0">
                                    <ul className="todolist">
                                        {rejectReason.length > 0 ? (
                                            rejectReason.map((reason, index) => {
                                                return (
                                                    <li>
                                                        <Link className="todolist-container active">
                                                            <div className="todolist-title">{index + 1}.</div>
                                                            <div className="todolist-title">
                                                                {reason.description}
                                                            </div>
                                                        </Link>
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <li style={{ textAlign: "center", margin: "0.5rem" }}>
                                                <div className="todolist-title">{t("NO_RECORD")}</div>
                                            </li>
                                        )}
                                    </ul>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div> :<></>}
            <div className="row" style={{ justifyContent: "space-between", margin: "0 20px" }}>
                <div className={"col-xl-" + (role1IsAllowed ? "2" : "12") + " col-md-" + (role1IsAllowed ? "6" : "12")}>
                    <div className="widget widget-stats bg-red">
                        <div className="stats-icon dailytask">
                            <i className="fa fa-solid fa-tasks"></i>
                        </div>
                        <div className="stats-info">
                            <h4>{t("DAILY_TASKS")} ({t("BY")} 12:00 p.m. {t("DAILY")})</h4>
                            <span>{t("VIP_BIRTHDAY_BONUS")}</span><br />
                            <span>{t("VIP_WEEKLY_RESCUE_BONUS")}</span><br />
                            <span>{t("VIP_COMMISSION")}</span><br />
                            <span>{t("MEMBER_GAME_POINTS")}</span><br />
                            <span>{t("SHAREHOLDERS_PROCESS")}</span><br />
                            <span>{t("POINTS_REDEMPTION")}</span>
                        </div>
                        <div className="stats-link">
                            <Link to={WebUrl._URL_BIRTHDAY_REWARDS}>
                                {t("VIEW_DETAIL")}{" "}
                                <i className="fa fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardV1;