import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { navigateTo } from "../../util/Util.js";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	AccessRight,
	TransactionType,
	TransactionStatus,
	PanelType,
	LanguageKey,
	BankingChannelName,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant.js";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction.js";
import { useDispatch, useSelector } from "react-redux";
import {
	stringIsNullOrEmpty,
	createFormBody,
	numberWithCurrencyFormat,
} from "../../util/Util.js";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import { AsyncPaginate } from "react-select-async-paginate";
import SweetAlert from "react-bootstrap-sweetalert";

const ReferralReportDetails = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const _PAGING_COUNT = 10;

    const [reportData, setReportData] = useState([]);
	const [selectedFilterMember, setSelectedFilterMember] = useState();
	const [selectedMember, setSelectedMember] = useState("");
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);

    let _tableColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            Cell: ({ row }) => {
              return (
                    <span>
						<a 
							href="javascript:void(0)"
							onClick={() => {
								navigateTo(
									_history,
									WebUrl._URL_REFERRAL_REPORT_DETAILS,
									{
										...row.original,
										startDate,
										endDate
									}
								);
							}}
						>
							{row.original.username}
						</a>
                        
                    </span>
                );
            },
            Footer: () => {
                return <span><b>{t("TOTAL")}: </b></span>
            },
        },
        {
            Header: "Phone Number",
            accessor: "phoneNumber",
            disableSortBy: true,
        },
        {
            Header: "Referral Code",
            accessor: "referralCode",
            disableSortBy: true,
        },
        {
            Header: "Remark",
            accessor: "remark",
            disableSortBy: true,
        },
        {
            Header: "Total Register",
            accessor: "totalRegister",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.totalRegister}
                    </span>
                );
            },
            Footer: () => {
                let totalRegister = 0;
                for (let i = 0; i <= reportData.length; i++) {
                    if (reportData[i] && reportData[i].totalRegister) {
                        totalRegister += reportData[i].totalRegister;
                    }
                }
                return (
                    <span>
                        <b>{totalRegister}</b>
                    </span>
                );
            },
        },
        {
            Header:"Total Deposit",
            accessor: "totalDeposit",
            Cell: ({ row }) => {
                let totalDeposit = parseFloat(row.original.totalDeposit);
                return (
                    <span>
                        {numberWithCurrencyFormat(totalDeposit, 3, true)}
                    </span>
                );
            },
            Footer: () => {
                let totalDeposit = 0;
                for (let i = 0; i <= reportData.length; i++) {
                    if (reportData[i] && reportData[i].totalDeposit) {
                        totalDeposit += parseFloat(reportData[i].totalDeposit);
                    }
                }
                return (
                    <span>
                        <b>{numberWithCurrencyFormat(totalDeposit, 3, true)}</b>
                    </span>
                );
            },
        },
        {
            Header:"Total Withdraw",
            accessor: "totalWithdraw",
            Cell: ({ row }) => {
                let totalWithdraw = parseFloat(row.original.totalWithdraw);
                return (
                    <span>
                        {numberWithCurrencyFormat(totalWithdraw, 3, true)}
                    </span>
                );
            },
            Footer: () => {
                let totalWithdraw = 0;
                for (let i = 0; i <= reportData.length; i++) {
                    if (reportData[i] && reportData[i].totalWithdraw) {
                        totalWithdraw += parseFloat(reportData[i].totalWithdraw);
                    }
                }
                return (
                    <span>
                        <b>{numberWithCurrencyFormat(totalWithdraw, 3, true)}</b>
                    </span>
                );
            },
        },
    ];


    useEffect(() => {
        getReferralReport();
    }, []);

	/// <summary>
	/// Author : -
	/// </summary>
	const getReferralReport = async () => {
		_dispatch(setBusy());
		try {
			var apiUrl =
				ApiUrl._API_GET_REFERRAL_REPORT +
				"?startDate=" +
				startDate +
				"&endDate=" +
				endDate;

			apiUrl += "&memberId=" + selectedMember;

			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setReportData(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		} catch (error) {
			_dispatch(showMessage(false, t(error)));
		}
        _dispatch(setIdle());
	};

	/// <summary>
	/// Author : Wong
	/// </summary>
	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.id };
			});
			formattedData.unshift({ label: "All", value: "" });
			return {
				options: formattedData,
				hasMore: formattedData.length == _PAGING_COUNT,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	}; /// <summary>

	return (
		<div>
			<h1 className="page-header">{t("REFERRAL_REPORT_TITLE")}</h1>
			<Panel>
				<div className="row">
					<div className="col-lg-12">
						<PanelBody>
							<div className="row">
								<div className="col-lg-3">
									<div className="form-group">
										<label>
											<b>{t("DATE")}</b>
										</label>
										<div
											className="input-group"
											style={{ width: "100%", flexFlow: "nowrap" }}
										>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-calendar"></i>
												</span>
											</div>
											<div style={{ width: "80%" }}>
												<DateRangePicker
													containerStyles={{ width: "100%" }}
													startDate={startDate}
													endDate={endDate}
													onApply={handleEvent}
													alwaysShowCalendars={true}
													locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
													timePicker={true}
													timePickerSeconds={true}
													ranges={{
														Today: [
															moment().startOf("day"),
															moment().endOf("day"),
														],
														Yesterday: [
															moment().subtract(1, "days").startOf("day"),
															moment().subtract(1, "days").endOf("day"),
														],
														"Last 7 Days": [
															moment().subtract(6, "days").startOf("day"),
															moment().endOf("day"),
														],
														"Last 30 Days": [
															moment().subtract(29, "days").startOf("day"),
															moment().endOf("day"),
														],
														"This Month": [
															moment().startOf("month"),
															moment().endOf("month"),
														],
														"Last Month": [
															moment().subtract(1, "month").startOf("month"),
															moment().subtract(1, "month").endOf("month"),
														],
														"This Year": [
															moment().startOf("year"),
															moment().endOf("year"),
														],
													}}
												>
													<input
														type="text"
														className="form-control"
														value={startDate + " - " + endDate}
													/>
												</DateRangePicker>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="form-group">
										<label>
											<b>{t("Username")}</b>
										</label>
										<AsyncPaginate
											debounceTimeout={250}
											loadOptions={getMemberList}
											additional={{
												page: 1,
											}}
											value={selectedFilterMember}
											onChange={(e) => {
												setSelectedFilterMember(e);
												setSelectedMember(e.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-2">
									<button
										type="button"
										onClick={() => {
											getReferralReport();
										}}
										className="btn btn-primary"
									>
										{t("SEARCH")}
									</button>
								</div>
							</div>
							<hr />
							<ReactTable
								data={reportData}
								columns={_tableColumns}
								renderFooter={true}
							/>
						</PanelBody>
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default ReferralReportDetails;
